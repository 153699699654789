





















import { Vue } from 'vue-property-decorator';

export default Vue.extend({
    functional: true,
    render(createElement, context) {
        return createElement('div', { class: 'iframe-wrap' }, [createElement('iframe', context ? context.data : undefined, context ? context.children : undefined)]);
    }
});
