






















import { Vue, Prop, Component } from 'vue-property-decorator';

import Spinner from '~/patterns/atoms/spinner/spinner.vue';

@Component({
    components: { Spinner }
})

export default class ZipcodeEntry extends Vue {
    @Prop() readonly state!: string;
}
