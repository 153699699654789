




















import * as UAParser from 'ua-parser-js';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { parseUrl } from '~/helpers/routes';
import eventBus from '~/helpers/event-bus';
import config from '~/config/config';

@Component({
    methods: { parseUrl }
})
export default class Link extends Vue {
    @Prop({ required: true }) readonly to!: string;
    @Prop() readonly ariaCurrent: string;
    /**
     * Is link keyboard accessible?
     */
    accessible: boolean = false;

    queryString: string = '';

    /**
     * Determine whether to use "exact" matching for the active state.
     * See https://router.vuejs.org/api/#exact
     */
    get exact(): boolean {
        const municipalitySlug = '/' + this.$store.getters['municipality/municipalitySlug'];
        return !!this.to && (this.to === '/' || this.to === '' || this.to === municipalitySlug);
    }

    get isMailto(): boolean {
        return !!this.to && this.to.startsWith('mailto:');
    }

    /**
     * Whether this is an external link
     */
    get isExternal(): boolean {
        return !!this.to && this.to.startsWith('http') && !this.to.startsWith(`https://${config.domain}`);
    }

    get searchParams(): string {
        if (this.to.includes('?')) {
            const search = this.to.split('?').pop();
            return this.queryString ? this.queryString + '&' + search : '?' + search;
        }
        return this.queryString;
    }

    /**
     * Determine whether to open this link in a modal for a "redirect to external page"-prompt
     */
    get isRedirectUrl(): boolean {
        const domain = parseUrl(this.to);
        return !!(domain && config.redirectDomain === domain);
    }

    get tabindex(): number {
        return this.accessible ? -1 : 0;
    }

    get destination(): string {
        const to = this.isExternal ? this.to : this.to.replace(`https://${config.domain}`, '') + '/';
        return this.searchParams && !this.isMailto ? to + this.searchParams : to;
    }

    openExternalLinkModal(event): void {
        event.preventDefault();
        this.$store.dispatch('modals/setExternalUrl', this.destination);
        eventBus.$emit('openModal', { id: 'external-link-modal' });
    }

    onKeydown(event): void {
        event.target.blur();
        if (!this.accessible) {
            this.$router.push({ path: this.destination });
        }
    }

    mounted() {
        this.queryString = window.location.search;

        const parser = new UAParser();
        const { engine } = parser.getResult();
        if (engine.name === 'Blink' || engine.name === 'Trident') {
            this.accessible = true;
        }
    }
}
