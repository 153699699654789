




import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class NavPushDown extends Vue {
    @Prop() readonly showSecondary: boolean;
}
