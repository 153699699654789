




import { Vue, Component, Prop } from 'vue-property-decorator';
import config from '~/config/config';
import { parseUrl } from '~/helpers/routes';

@Component({
    methods: { parseUrl }
})
export default class EmbeddedIframe extends Vue {
    @Prop() readonly src: string;
    @Prop() readonly title: string;

    get sandbox(): string {
        // Determine if the src attribute's domain is trusted in our config, if so, allow more code execution.
        if (this.src) {
            const domain = parseUrl(this.src);
            if (domain && config.trustedIframeDomains.find((item): boolean => item === domain)) {
                return 'allow-scripts allow-same-origin';
            }
            return '';
        }
        return '';
    }
}
