


















import { Vue, Component, Prop } from '~/node_modules/vue-property-decorator';

@Component({})
export default class Stars extends Vue {
    @Prop({ required: true }) readonly total: number;
    @Prop({ required: true }) readonly filled: number;
}
