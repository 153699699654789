






import { Vue, Prop, Component } from 'vue-property-decorator';

@Component({})
export default class Spinner extends Vue {
    @Prop() readonly title!: string;
}


