












import { Vue, Component, Prop } from 'vue-property-decorator';
import Spinner from '~/patterns/atoms/spinner/spinner.vue';

@Component({
    components: { Spinner }
})
export default class Button extends Vue {
    @Prop({ default: false }) readonly loading!: boolean;
}
