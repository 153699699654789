




import { Vue, Component, Prop } from 'vue-property-decorator';
import { HasImage } from '~/interfaces/image';
import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import { getImage } from '~/helpers/image';
import { MunicipalityFields } from '~/types/Municipality';

@Component({
    components: { CloudinaryImage }
})
export default class MunicipalityLogo extends Vue {
    @Prop() readonly municipality!: MunicipalityFields;

    get hasLogo(): boolean {
        return !!this.image.cloudinaryImage;
    }

    get municipalityName(): string {
        if (this.municipality) {
            return this.municipality.name;
        }
        return '';
    }

    /*
     * Attempt to trim 'Gemeente' from name, for use on smaller devices.
     */
    get municipalityShortName(): string {
        if (this.municipality.name && this.municipality.name.indexOf('Gemeente') !== -1) {
            return this.municipality.name.substr(9);
        }
        return this.municipality.name;
    }

    get isPartner(): boolean {
        return this.municipality?.relDomain?.includes(this.$store.getters['site/site']);
    }

    get image(): HasImage {
        return getImage(this.municipality as MunicipalityFields, 'logo', 'logoCloudinary');
    }
}
