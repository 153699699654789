





















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class CloudinaryImage extends Vue {
    @Prop() readonly width?: number;
    @Prop() readonly height?: number;
    @Prop() readonly sizes!: string;
    @Prop() readonly alt: string;

    @Prop() readonly classes!: string;

    // eslint-disable-next-line camelcase, @typescript-eslint/member-delimiter-style
    @Prop({ required: true }) readonly image: { public_id: string; context: { custom: { alt: string } } };

    @Prop({ default: () => [1366, 992, 768, 576, 340] }) sourceSizes: number[];

    @Prop({ default: () => [1200, 992, 768, 576, 340] }) breakpoints: number[];

    generateUrl(width: number): string | undefined {
        // Cloudinary image objects contain snake_cased properties..
        // eslint-disable-next-line dot-notation
        if (this.image && this.image['public_id']) {
            const url =
                `//media.regionaalenergieloket.nl/image/upload/w_${width},q_auto,f_auto/` +
                // eslint-disable-next-line dot-notation
                this.image['public_id'];
            return encodeURI(url);
        }
        return undefined;
    }

    get sources(): string {
        return this.sourceSizes
            .map((size, index): string | undefined => {
                const url = this.generateUrl(size);
                if (url) {
                    return `${url} ${this.breakpoints[index]}w`;
                }
                return undefined;
            })
            .join(', ');
    }

    get displaySizes(): string {
        return (
            this.sizes ||
            this.sourceSizes
                .map((size, index): string => {
                    return `(max-width: ${this.breakpoints[index]}px) ${size}px`;
                })
                .join(', ')
        );
    }

    get defaultSrc(): string {
        return this.generateUrl(this.sourceSizes[0]) as string;
    }

    get altText(): string | false {
        if (this.alt === '') {
            return false;
        } else if (this.alt && this.alt.length > 0) {
            return this.alt;
        } else if (this.image && this.image.context && this.image.context.custom && this.image.context.custom.alt) {
            return this.image.context.custom.alt;
        }

        return false;
    }
}
