






import { Vue, Component, Prop } from 'vue-property-decorator';
import { Block, Text } from '@contentful/rich-text-types';

@Component({
    name: 'gh-heading'
})
export default class GhHeading extends Vue {
    @Prop() node: Block;
    @Prop() headingClass: string;
    @Prop({ default: 1 }) level: number;

    get text(): string {
        if (!this.node) {
            return '';
        }
        const content = this.node.content[0] as Text;
        return content.value || '';
    }

    get heading(): string {
        return this.node ? this.node.nodeType : this.headingClass;
    }

    get tag(): string {
        return isNaN(this.level) ? 'h3' : 'h' + this.level;
    }
}
