
import { Vue, Component, Prop } from 'vue-property-decorator';
import { MunicipalityType } from '~/types/Municipality';

@Component({})
export default class DisplayLogicMixin extends Vue {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Prop() readonly fields!: any;

    @Prop() readonly municipality!: MunicipalityType;

    get display(): boolean {
        if (process.client && this.fields) {
            const params = new URLSearchParams(window.location.search);
            if (params.has('hide') && params.get('hide') === this.fields?.hideOnParam) {
                return false;
            }
        }

        const municipality = this.municipality || this.$store.getters['municipality/municipality'];
        if (this.fields.hideOnMunicipalities?.length) {
            const excludeCodes = this.fields.hideOnMunicipalities.map(
                (item: MunicipalityType) => item.fields.municipality
            );
            if (!!municipality?.fields && excludeCodes.includes(municipality.fields.municipality)) {
                return false;
            }
        }
        if (this.fields.municipalities?.length) {
            const gmCodes = this.fields.municipalities.map((item: MunicipalityType) => item.fields.municipality);
            return !!municipality?.fields && gmCodes.includes(municipality.fields.municipality);
        }
        return true;
    }
}
