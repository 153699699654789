




import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { trimStart } from 'lodash';

@Component({})
export default class TabLink extends Vue {
    @Prop({ type: Number }) readonly id!: number;

    @Prop({ type: String }) readonly slug!: string;

    @Prop({ type: Number }) activeId!: number;

    get href(): string {
        return '#' + this.slug;
    }

    get isActive(): boolean {
        return this.activeId === this.id;
    }

    @Watch('isActive')
    activeUpdated() {
        if (this.isActive) {
            const button = this.$refs.button as HTMLElement;
            button.focus();
        }
    }

    setActiveIdAndRoute() {
        this.$router.push({ query: Object.assign({}, this.$route.query, { page: 1 }), hash: this.$route.hash });
        this.setActiveId();
    }

    setActiveId() {
        this.$emit('update:activeId', this.id);
    }

    mounted() {
        const hash = this.$route.hash;
        if (hash) {
            const slug = trimStart(hash, '#');
            if (slug === this.slug) {
                this.setActiveId();
            }
        }
    }
}
