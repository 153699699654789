







import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    components: { RichText: () => import('~/patterns/molecules/rich-text/rich-text.vue') }
})
export default class CustomMark extends Vue {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Prop() readonly text: any;
    @Prop() readonly type: 'bold' | 'italic';

    get isString() {
        return this.text.length > 0 && typeof this.text[0] === 'string';
    }

    get processedText() {
        if (this.text.length > 0 && typeof this.text[0] === 'string') {
            return this.text[0];
        } else if (
            this.text.length > 0 &&
            this.text[0] &&
            this.text[0].componentOptions &&
            this.text[0].componentOptions.propsData &&
            this.text[0].componentOptions.propsData.text &&
            this.text[0].componentOptions.propsData.text[0]
        ) {
            return this.text[0].componentOptions.propsData.text[0];
        } else {
            return '';
        }
    }
}
