




import { Vue, Component, Prop } from 'vue-property-decorator';

export const TYPE_FACEBOOK = 'facebook';
export const TYPE_LINKEDIN = 'linkedin';
export const TYPE_TWITTER = 'twitter';

@Component({})
export default class ButtonSocial extends Vue {
    @Prop() readonly href!: string;

    @Prop() readonly classes!: string;

    @Prop() readonly type!: string;

    get iconClass() {
        switch (this.type) {
            case TYPE_FACEBOOK:
                return 'fab fa-facebook-f';
            case TYPE_LINKEDIN:
                return 'fab fa-linkedin-in';
            case TYPE_TWITTER:
                return 'fab fa-twitter';
            default:
                return '';
        }
    }
}
